type TypedArray = Int8Array | Uint8Array | Uint8ClampedArray | Int16Array | Uint16Array | Int32Array | Uint32Array;

export function getInsecureRandomValues(array: TypedArray | null): TypedArray | null {
  if (!array) {
    return array;
  }

  // Bitwise operations in JavaScript are performed on 32 bits signed integers
  // 1 << 32 resolves as 1
  // (1 << 32) - 1 resolves as 0
  // That is why Math.pow used here
  const typedMaxSafeValue: number = Math.pow(2, array.BYTES_PER_ELEMENT << 3) - 1;

  for (let i = 0; i < array.length; i++) {
    array[i] = Math.floor(Math.random() * typedMaxSafeValue);
  }

  return array;
}
